import axios from "axios";
import store from "@/store";
import { server } from "@/services/constants";

// const apiUrl = "https://yrmr0ubupl.execute-api.ap-southeast-1.amazonaws.com/dev";
const apiUrl = "https://7og1w3pr1l.execute-api.ap-southeast-1.amazonaws.com/production";
const apiUrl2 = "https://i9xmmtqrmf.execute-api.ap-southeast-1.amazonaws.com/Prod";

const getExpireTime = (sec) => {
    const t = new Date();
    if (sec > 0)
        t.setSeconds(t.getSeconds() + sec);
    return parseInt(t / 1000); //utc datetime
}

const getToken = async () => {
    var d1 = localStorage.getItem(server.EXPIRES_TIME) ?? 0;
    if (d1 == null)
        d1 = 0;
    if (d1 > 0) {
        var d2 = getExpireTime(0);
        if (d2 > d1) {
            try {
                let response = await refresh_token();
                // console.log("--- refreshToken ---", d1, d2, response);
                localStorage.setItem(server.ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(server.ID_TOKEN, response.idToken);
                localStorage.setItem(server.EXPIRES_IN, response.expiresIn);
                localStorage.setItem(server.EXPIRES_TIME, getExpireTime(response.expiresIn - 120));
                return response.idToken;
            } catch (error) {
                store.dispatch("doLogout");
            }
        }
    }
    let token = localStorage.getItem(server.ID_TOKEN) ?? "";
    if (token==null || token.toString().trim()=="")
        store.dispatch("doLogout")
    else 
        return token;
};

const getInstanceWithOutToken = async (api_url) => {
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            'Content-Type': `application/json`
        },
        timeout: 600000
    });
    return instance;
};

const getInstance = async (api_url) => {
    const token = await getToken();
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            // 'Authorization': `Bearer ${token}`, 
            'Authorization': `${token}`, 
            'Content-Type': `application/json`
        },
        timeout: 600000
    });
    return instance;
};

const refresh_token = async () => {
    let userCode = localStorage.getItem(server.USERNAME) ?? "";
    let refreshToken = localStorage.getItem(server.REFRESH_TOKEN) ?? "";
    const instance = await getInstanceWithOutToken(apiUrl2);
    let payload = {
        "userCode": userCode,
        "refreshToken": refreshToken
    };
    return (
        await instance.post("/refresh_token", payload)
    ).data;
};

const getItems = async (nextToken) => {
    const instance = await getInstance(apiUrl);
    if (nextToken == null || nextToken == "")
        return (
            await instance.get("/admin/questions")
        ).data;
    else
        return (
            await instance.get("/admin/questions", 
            { 
                params: {
                    nextToken: nextToken
                }
            })
        ).data;
};

const getItem = async (id) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/admin/question/${id}`)
    ).data;
};

const createItem = async (data) => {
    const instance = await getInstance(apiUrl);
    let payload = data
    return (
        await instance.post("/admin/question/create", payload)
    ).data;
};

const updateItem = async (data) => {
    const instance = await getInstance(apiUrl);
    let payload = data
    return (
        await instance.post("/admin/question/update", payload)
    ).data;
};

const deleteItem = async (id) => {
    const instance = await getInstance(apiUrl);
    let payload = {
        "QuestionID": id
    };
    return (
        await instance.post("/admin/question/delete", payload)
    ).data;
};

export default {
    getItems,
    getItem,
    createItem,
    updateItem,
    deleteItem,
};