import { render, staticRenderFns } from "./ImportProductAttributeAlcohol.vue?vue&type=template&id=00fbe194&scoped=true"
import script from "./ImportProductAttributeAlcohol.vue?vue&type=script&lang=js"
export * from "./ImportProductAttributeAlcohol.vue?vue&type=script&lang=js"
import style0 from "./ImportProductAttributeAlcohol.vue?vue&type=style&index=0&id=00fbe194&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00fbe194",
  null
  
)

export default component.exports