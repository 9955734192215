<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Import Product Meta</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <v-divider></v-divider>
            <br />
            <template>
              <v-row class="ma-0">
                <h3 class="mr-3">Import Excel (XLSX)</h3>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <!-- <v-spacer></v-spacer>
                <v-btn color="primary" @click="clearData">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn> -->
              </v-row>
            </template>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "import-product-meta",
  // components:{
  // },
  data: () => ({
    loading: false,
    mDataArray: []
  }),
  // async mounted() {
  // },
  methods: {
    cancel() {
      this.$router.back();
    },
    clearData() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));

            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            // let prcodeList = [];
            // prcodes.forEach(item => {
            //   prcodeList.push(parseInt(item.prcode ?? 0));
            // });
            var count = 0;
            let dataArray = [];
            prcodes.forEach(item => {
                var itemUpdate = {
                    "seq": ++count,
                    "cprcode": parseInt(item["prcode"]),
                    "meta_title": item["meta_title"],
                    "meta_keywords": item["meta_keywords"],
                    "meta_description": item["meta_description"]
                };
                dataArray.push(itemUpdate);
            });
            // this.mDataArray = dataArray;
            if (dataArray && dataArray.length>0) {
                var isNewStore = 0;
                if (vm.isNewStore) {
                  isNewStore = 1;
                };
                var start = 0;
                var stop = 0;
                while (stop <= dataArray.length) {
                    stop = start + 1000;
                    var dataUpdates = dataArray.filter(o => o.seq >= start && o.seq <= stop)
                    start = stop + 1;
                    // console.log('-- dataUpdates --', JSON.stringify(dataUpdates));
                    var result = await api.setProductMetaImport(JSON.stringify(dataUpdates));
                    console.log('-- result --',result);
                }
                vm.$router.back();
            }
        };
        reader.readAsArrayBuffer(file);
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    pickers() {
      if (this.mPickers == null || this.orderItem == null)
        return []
      else {
        return this.mPickers.filter(o => o.brcode == this.orderItem.branchId)
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.1rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
.table_store {
   height: 270px;
   overflow: auto;
}
.subject {
  color: #0D47A1;
}
</style>