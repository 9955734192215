<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-1 pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <v-col cols="6">
                    <v-text-field
                        outlined
                        v-model="survey.QuestionID"
                        label="QuestionID"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        outlined
                        v-model="survey.Question"
                        label="Question"
                        clearable
                    ></v-text-field>
                    <v-datetime-picker
                        v-model="startTime"
                        datetime="String"
                        label="StartTime"
                    ></v-datetime-picker>
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        v-model="survey.Required"
                        label="Required"
                    ></v-checkbox>
                    <v-select class="mt-5"
                        outlined
                        v-model="survey.Type"
                        label="Type"
                        :items="['TEXT','RATING','MULTIPLE_CHOICE']"
                    ></v-select>
                    <v-datetime-picker
                        v-model="endTime"
                        datetime="String"
                        label="EndTime"
                    ></v-datetime-picker>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <br />
              <template>
                <v-row>
                  <v-col cols="6">
                      <v-row class="ma-0">
                          <h3>Options</h3>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-text-field
                            v-model="description"
                            label="Description"
                            clearable
                          ></v-text-field>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-btn class="mr-3" color="primary" @click="addDescription">
                              <v-icon left>add</v-icon>Add
                          </v-btn>
                      </v-row>

                      <div class="table_survey" v-if="options != null">
                        <table class="table table-striped" width="100%">
                            <thead class="head-dark" align-center>
                            <tr>
                                <th width="80%" style="text-align:left">Description</th>
                                <th scope="20%" style="text-align:left">Delete</th>
                            </tr>
                            </thead>
                            <draggable v-model="options" tag="tbody">
                                <tr v-for="item, index in options" :key="item + index">
                                    <td>{{ item }}</td>
                                    <td>
                                        <v-icon class="ml-3" @click="removeItem(options, item)">
                                            delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                      </div>
                  </v-col>
                </v-row>
              </template>
  
              <v-divider></v-divider>
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
import surveyService from "@/services/surveyService";
import draggable from 'vuedraggable'

export default {
    name: "survey-edit",
    components: {
        draggable
    },
    data: () => ({
      loading: false,
      ID: "",
      description: "",
      startTime: "",
      endTime: "",
      survey: {
        QuestionID: "",
        Question: "",
        Type: "",
        Options: "",
        Required: false,
        Metadata: "",
        Creator: "",
        // CreatedAt: "",
        UpdatedAt: "",
        StartTime: "",
        EndTime: "",
        // ViewCount: 0
      },
      options: [],
    }),
    async mounted() {
      try {
        this.loading = true;
        this.ID = this.$route.params.id ?? "";
        if (this.ID != "")
        {
          let result = await surveyService.getItem(this.ID);
          if (result.Status == "success") {
            if (result) {
                this.survey = result.Data;
                this.startTime = new Date(this.survey?.StartTime);
                this.endTime = new Date(this.survey?.EndTime);
                if (this.survey.Options)
                  this.options = JSON.parse(this.survey.Options);
                else
                  this.options = []
            }
          }
        }
      } catch (error) {
        alert(error)
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      removeItem: function (objectList, item) {
        let index = objectList.indexOf(item);
        if (index >= 0) {
          objectList.splice(index, 1)
        }
      },
      addDescription() {
        if (this.description == null || this.description.trim() == "") return;
        let index = this.options.indexOf(this.description);
        if (index == -1) this.options.push(this.description);
        this.description = "";
      },
      cancel() {
        this.$router.back();
      },
      async submit() {
        try {
          this.loading = true;
          let result;
          var start_time = new Date(this.startTime);
          var end_time = new Date(this.endTime);
          let data = {
            //QuestionID: this.ID,
            Question: this.survey.Question,
            Type: this.survey.Type,
            Options: this.survey.Type == "" || this.survey.Type == "TEXT" ? "" : JSON.stringify(this.options),
            Required: this.survey.Required,
            Creator: this.userCode,
            StartTime: start_time.toISOString(),
            EndTime: end_time.toISOString(),
          };
          // console.log(JSON.stringify(data));
          if (this.ID == "") {
            result = await surveyService.createItem(data);
          }
          else {
            data["QuestionID"] = this.survey.QuestionID;
            result = await surveyService.updateItem(data);
          }
          // console.log(JSON.stringify(result));
          // if (result.Status == "success")
          //     alert('Save Success.')
          this.$router.back();
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      },
    },
    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
    }
  };
</script>
  
<style scoped>
table {
  border-spacing: 0 0.5rem;
}
.table_survey {
   height: 260px;
   overflow: auto;
}
td {
   padding: 5px;
   vertical-align: top;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
.header {
  font-size: 20px;
  font-weight: bold;
  color: #0D47A1;
  background-color: rgba(0, 0, 0, .05);
}
</style>