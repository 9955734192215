import { render, staticRenderFns } from "./ImportProductMeta.vue?vue&type=template&id=3912557e&scoped=true"
import script from "./ImportProductMeta.vue?vue&type=script&lang=js"
export * from "./ImportProductMeta.vue?vue&type=script&lang=js"
import style0 from "./ImportProductMeta.vue?vue&type=style&index=0&id=3912557e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3912557e",
  null
  
)

export default component.exports