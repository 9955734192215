<template>
    <v-card>
      <v-app-bar
        app
        :fixed="true"
        color="primary"
        rounded="0"
        dark
        dense>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
  
        <!-- <v-toolbar-title>{{ title }} V{{ version }}</v-toolbar-title> -->
        <v-toolbar-title>{{ title }}</v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <span>{{ $store.getters["userInfo"]["userFirstName"] }}</span>
        <v-btn icon @click="onClickLogOff">
          <v-icon>mdi-export</v-icon>
        </v-btn>
  
      </v-app-bar>
          <!-- absolute
          bottom -->
      <v-navigation-drawer 
          v-model="drawer"
          app 
          dark 
          temporary
          src="@/assets/background_menu.jpg">
          <!-- <router-link to="" exact> -->
          <v-img src="@/assets/vue_display.png" alt="" width="100%" />
          <!-- </router-link> -->
          <v-list shaped>
              <v-list-item-group v-model="group" mandatory color="primary">
              <v-list-item       
              class="tile" 
                  v-for="([icon, title, route], index) in menus"
                  :key="index"
                  @click="onClickMenu(route, title)">
                  <v-list-item-icon>
                  <v-icon color="white">{{ icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                  <v-list-item-title>{{ title }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              </v-list-item-group>
          </v-list>
      </v-navigation-drawer>
      <!-- <v-card-text>
          <template>
              <v-main>
                  <keep-alive>
                  <router-view v-if="$route.meta.keepAlive"/>
                  </keep-alive>
                  <router-view v-if="!$route.meta.keepAlive"/>
              </v-main>
          </template>
      </v-card-text> -->
      <template>
          <v-main>
              <keep-alive>
              <router-view v-if="$route.meta.keepAlive"/>
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive"/>
          </v-main>
      </template>
    </v-card>
  </template>
  
  <script>
  export default {
      name: "MainMenu",
      data: () => ({
          drawer: false,
          group: null,
          menuName: "",
          menus: []
      }),
      watch: {
          group () {
              this.drawer = false
          },
      },
      mounted() {
          // this.group = this.menus.findIndex(
          //   menu => menu[2] == this.$route.path
          // );
          this.setMenu();
      },
      methods: {
          onClickMenu(link, menuName) {
              this.menuName = menuName;
              this.$router.push(link).catch(err => {});
          },
          setMenu() {
              // console.log('-- userGroup --', this.userGroup);
              switch (this.userGroup) {
                  case "marketing":
                      if (this.userType=="Admin") {
                          this.menus = [
                              ["dashboard", "Dashboard", "/dashboard/salereport"],
                              ["ballot", "Order Status", "/deliverystatus"],
                              ["ballot", "Banner", "/banner/index"],
                              ["ballot", "YouTube", "/youtube/edit"],
                              ["ballot", "Article", "/article/index"],
                              ["ballot", "Product Recommended", "/productrecommended/index"],
                              ["ballot", "Market Place", "/partnershipshops/index"],
                              ["ballot", "New Arrival", "/newarrival/index"],
                              ["ballot", "Best Seller", "/bestseller/index"],
                              ["ballot", "Grab & Go", "/grabandgo/index"],
                          ]
                          if (this.userCode?.toLowerCase() == "85211595-29ec-4cb0-9fb7-781aaefe9c4f") {
                              this.menus.push(["ballot", "Geo Content", "/geocontent/index"])
                              this.menus.push(["ballot", "Product Meta", "/geocontent/product"])
                              this.menus.push(["ballot", "Send Noti", "/sendnoti"])
                              this.menus.push(["ballot", "Campaign Content", "/campaignscontent/index"])
                              this.menus.push(["ballot", "Campaign Tracking", "/campaigntracking"])
                              this.menus.push(["ballot", "Survey", "/survey/index"])
                              this.menus.push(["ballot", "Product Rating", "/productrating/index"])
                              // this.menus.push(["ballot", "Send Notify", "/sendnotify/index"])
                              // this.menus.push(["ballot", "Pending Items", "/pendingitems/index"])
                          }
                      }
                      else {
                          this.menus = [
                              ["ballot", "Home", "/home"],
                              ["ballot", "Banner", "/banner/index"],
                              ["ballot", "YouTube", "/youtube/edit"],
                              ["ballot", "Article", "/article/index"],
                          ]
                      }
                      break;
                  case "operation":
                      if (this.userType=="Picker" || this.userType=="User")
                          this.menus = [
                              ["ballot", "Home", "/home"],
                              ["ballot", "Assigned Pickers", "/picking/assignpicker"],
                              ["ballot", "Picking", "/picking/index"],
                              ["ballot", "Print Sticker", "/picking/printsticker"],
                          ]
                      else if (this.userType=="Admin")
                          if (this.userCode=="V12144" || this.userCode=="V12323" || this.userCode=="V16028") {
                              this.menus = [
                                  // ["ballot", "Dashboard", "/home"],
                                  ["dashboard", "Dashboard", "/dashboard/salereport"],
                                  ["ballot", "Order Status", "/deliverystatus"],
                                  ["ballot", "Order", "/order/index"],
                                  ["ballot", "Pre-Order", "/preorderorder/index"],
                                  ["ballot", "Nationwide", "/nationwide/index"],
                                  ["ballot", "Assigned Pickers", "/picking/assignpicker"],
                                  ["ballot", "Picking", "/picking/index"],
                                  ["ballot", "Print Sticker", "/picking/printsticker"],
                                  ["ballot", "Inventory", "/inventory/index"],
                                  ["ballot", "Online Warehouse", "/onlinewarehouse/index"],
                              ]
                          }
                          else {
                              this.menus = [
                                  // ["ballot", "Dashboard", "/home"],
                                  ["dashboard", "Dashboard", "/dashboard/salereport"],
                                  ["ballot", "Order Status", "/deliverystatus"],
                                  ["ballot", "Order", "/order/index"],
                                  ["ballot", "Pre-Order", "/preorderorder/index"],
                                  ["ballot", "Nationwide", "/nationwide/index"],
                                  ["ballot", "Assigned Pickers", "/picking/assignpicker"],
                                  ["ballot", "Picking", "/picking/index"],
                                  ["ballot", "Print Sticker", "/picking/printsticker"],
                                  ["ballot", "Inventory", "/inventory/index"],
                              ] 
                          }
                      else //Super User Jaturong
                          this.menus = [
                              ["dashboard", "Dashboard", "/dashboard/salereport"],
                              ["ballot", "Order Status", "/deliverystatus"],
                              ["ballot", "Reports", "/reports/index"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Pre-Order", "/preorderorder/index"],
                              ["ballot", "Nationwide", "/nationwide/index"],
                              ["ballot", "Assigned Pickers", "/picking/assignpicker"],
                              ["ballot", "Picking", "/picking/index"],
                              ["ballot", "Print Sticker", "/picking/printsticker"],
                              ["ballot", "Inventory", "/inventory/index"],
                              ["ballot", "Online Warehouse", "/onlinewarehouse/index"],
                              ["ballot", "Voucher Request", "/voucher/voucherrequest"],
                              ["ballot", "Notify Out Of Stock", "/pendingitems/index"],
                              ["ballot", "Campaign Tracking", "/campaigntracking"],
                          ]
                      break;
                  case "cs":
                      if (this.userCode == "V21582" || this.userCode == "V17842")
                          this.menus = [
                                  ["ballot", "Home", "/home"],
                                  ["ballot", "Order", "/order/index"],
                                  ["ballot", "Inventory", "/inventory/index"],
                                  ["ballot", "Voucher Request", "/voucher/voucherrequest"],
                              ]
                      else
                          this.menus = [
                              ["ballot", "Home", "/home"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Inventory", "/inventory/index"],
                          ]
                      break;
                  case "account":
                      if (this.userCode == "V1944") {
                          this.menus = [
                              ["ballot", "Home", "/home"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Auto Refund", "/voucher/autorefund"],
                              ["ballot", "Voucher Request", "/voucher/voucherrequest"],
                              ["ballot", "Inventory", "/inventory/index"],
                          ]
                      }
                      else {
                          this.menus = [
                              ["ballot", "Home", "/home"],
                              ["ballot", "Order", "/order/index"],
                          ]
                      }
                      // if (this.userCode == "V1944") {
                      //     this.menus.append(["ballot", "Auto Refund", "/voucher/autorefund"]);
                      // }
                      break;
                  case "superuser":
                      this.menus = [
                          ["ballot", "Home", "/home"],
                          ["ballot", "Order", "/order/index"],
                          ["ballot", "Inventory", "/inventory/index"],
                          ["ballot", "Product", "/product/index"]
                      ]
                      break;
                  case "buyer":
                      if (this.userType=="Admin") {
                          this.menus = [
                              ["dashboard", "Dashboard", "/dashboard/salereport"],
                              ["ballot", "Order Status", "/deliverystatus"],
                              // ["ballot", "Manual Stock", "/manualstock/index"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Picking", "/picking/index"],
                              ["ballot", "Inventory", "/inventory/index"],
                              ["ballot", "Product", "/product/index"],
                              ["ballot", "Product Sequence ", "/product/seq"],
                              ["ballot", "Product Import", "/product/import"],
                              ["ballot", "Product Recommended", "/productrecommended/index"],
                              // ["ballot", "Butcher Shop", "/butchershop/index"],
                              ["ballot", "Market Place", "/partnershipshops/index"],
                              ["ballot", "Valued Pack", "/valuedpack/index"],
                              ["ballot", "New Arrival", "/newarrival/index"],
                              ["ballot", "Best Seller", "/bestseller/index"],
                              ["ballot", "Grab & Go", "/grabandgo/index"],
                              ["ballot", "Pre Order", "/preorder/index"],
                              ["ballot", "Healthy", "/health/index"],
                              ["ballot", "Seasonal", "/seasonal/index"],
                              ["ballot", "Summer", "/summer/index"],
  
                              ["ballot", "Beef", "/beef/index"],
                              ["ballot", "Pork", "/pork/index"],
                              ["ballot", "Chicken & Duck", "/chickenandduck/index"],
                              ["ballot", "Lamb", "/lamb/index"],
                              ["ballot", "Seafood", "/seafood/index"],
                              ["ballot", "Fruits", "/fruits/index"],
                              ["ballot", "Vegetables", "/vegetables/index"],
                              ["ballot", "Only At Villa", "/onlyatvilla/index"],
  
                              // ["ballot", "Fruits & Vegetables", "/fruitsandvegetables/index"],
                              ["ballot", "Special Offer", "/specialoffer/index"],
                              // ["ballot", "Iceland", "/Iceland/index"],
                              // ["ballot", "Monoprix", "/Monoprix/index"],
                              // ["ballot", "Woolworths", "/Woolworths/index"],
                              ["ballot", "Gourmet Line", "/gourmetline/edit"],
                          ]
                      }
                      else {
                          this.menus = [
                              ["ballot", "Dashboard", "/home"],
                              // ["ballot", "Manual Stock", "/manualstock/index"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Picking", "/picking/index"],
                              ["ballot", "Inventory", "/inventory/index"],
                              ["ballot", "Product", "/product/index"],
                              ["ballot", "Product Sequence ", "/product/seq"],
                              ["ballot", "Product Import", "/product/import"],
                              ["ballot", "Product Recommended", "/productrecommended/index"],
                              // ["ballot", "Butcher Shop", "/butchershop/index"],
                              ["ballot", "Market Place", "/partnershipshops/index"],
                              ["ballot", "Valued Pack", "/valuedpack/index"],
                              ["ballot", "New Arrival", "/newarrival/index"],
                              ["ballot", "Best Seller", "/bestseller/index"],
                              ["ballot", "Grab & Go", "/grabandgo/index"],
                              ["ballot", "Pre Order", "/preorder/index"],
                              ["ballot", "Healthy", "/health/index"],
                              ["ballot", "Seasonal", "/seasonal/index"],
                              ["ballot", "Summer", "/summer/index"],
  
                              ["ballot", "Beef", "/beef/index"],
                              ["ballot", "Pork", "/pork/index"],
                              ["ballot", "Chicken & Duck", "/chickenandduck/index"],
                              ["ballot", "Lamb", "/lamb/index"],
                              ["ballot", "Seafood", "/seafood/index"],
                              ["ballot", "Fruits", "/fruits/index"],
                              ["ballot", "Vegetables", "/vegetables/index"],
                              ["ballot", "Only At Villa", "/onlyatvilla/index"],
  
                              // ["ballot", "Fruits & Vegetables", "/fruitsandvegetables/index"],
                              ["ballot", "Special Offer", "/specialoffer/index"],
                              // ["ballot", "Iceland", "/Iceland/index"],
                              // ["ballot", "Monoprix", "/Monoprix/index"],
                              // ["ballot", "Woolworths", "/Woolworths/index"],
                              ["ballot", "Gourmet Line", "/gourmetline/edit"],
                          ] 
                      }
                      break;
                  default:
                      // Geekcon    
                      if (this.userCode?.toLowerCase() == "0ae45829-7c55-4cbb-a1f6-e81936c91255") {
                          this.menus = [
                              ["ballot", "Geo Content", "/geocontent/index"],
                              ["ballot", "Product Meta", "/geocontent/product"],
                              // ["ballot", "Campaign Content", "/campaignscontent/index"]
                          ]
                      }
                      else
                      // Red carbon
                      if (this.userCode?.toLowerCase() == "dcb9b390-f12c-4009-ba62-c3f788ffca76" || this.userCode?.toLowerCase() == "f8c3adfe-ff82-445c-93dc-5f52cbdb4e89") {
                          this.menus = [
                              ["ballot", "Send Noti", "/sendnoti"],
                          ]
                      }
                      else {
                          this.menus = [
                              // ["ballot", "Dashboard", "/home"],
                              ["dashboard", "Dashboard", "/dashboard/salereport"],
                              ["ballot", "Order Status", "/deliverystatus"],
                              ["ballot", "Reports", "/reports/index"],
                              // ["ballot", "Geo Content", "/geocontent/index"],
                              ["ballot", "Customer Saved Card", "/customersavedcard/index"],
                              ["ballot", "Notify Out Of Stock", "/pendingitems/index"],
                              ["ballot", "Send Notify", "/sendnotify/index"],
                              ["ballot", "User", "/user/index"],
                              // ["ballot", "Lazada", "/lazada/index"],
                              ["ballot", "Order", "/order/index"],
                              ["ballot", "Pre-Order", "/preorderorder/index"],
                              ["ballot", "Nationwide", "/nationwide/index"],
                              ["ballot", "Assigned Pickers", "/picking/assignpicker"],
                              ["ballot", "Picking", "/picking/index"],
                              ["ballot", "Print Sticker", "/picking/printsticker"],
                              ["ballot", "Online Warehouse", "/onlinewarehouse/index"],
                              ["ballot", "Inventory", "/inventory/index"],
                              ["ballot", "Banner", "/banner/index"],
                              ["ballot", "YouTube", "/youtube/edit"],
                              // ["ballot", "Coupon", "/promotion/index"],
                              ["ballot", "Coupon2", "/coupon/index"],
                              ["ballot", "Coupon3", "/coupon3/index"],
                              // ["ballot", "Coupon Group", "/coupongroup/index"],
                              // ["ballot", "Cancel Order", "/order/cancel"],
                              ["ballot", "Auto Refund", "/voucher/autorefund"],
                              ["ballot", "Voucher", "/voucher/index"],
                              ["ballot", "Voucher Request", "/voucher/voucherrequest"],
                              ["ballot", "Ready To Go", "/readytogo/index"],
                              // ["ballot", "Manual Stock", "/manualstock/index"],
                              ["ballot", "Product", "/product/index"],
                              // ["ballot", "Product Search", "/product/search"],
                              ["ballot", "Product Sequence ", "/product/seq"],
                              ["ballot", "Product Search Sequence ", "/product/searchseq"],
                              ["ballot", "Product Import", "/product/import"],
                              ["ballot", "Product Recommended", "/productrecommended/index"],
                              // ["ballot", "Butcher Shop", "/butchershop/index"],
                              ["ballot", "Market Place", "/partnershipshops/index"],
                              ["ballot", "Valued Pack", "/valuedpack/index"],
                              ["ballot", "New Arrival", "/newarrival/index"],
                              ["ballot", "Best Seller", "/bestseller/index"],
                              ["ballot", "Grab & Go", "/grabandgo/index"],
                              ["ballot", "Pre Order", "/preorder/index"],
                              ["ballot", "Healthy", "/health/index"],
                              ["ballot", "Seasonal", "/seasonal/index"],
                              ["ballot", "Summer", "/summer/index"],
  
                              ["ballot", "Beef", "/beef/index"],
                              ["ballot", "Pork", "/pork/index"],
                              ["ballot", "Chicken & Duck", "/chickenandduck/index"],
                              ["ballot", "Lamb", "/lamb/index"],
                              ["ballot", "Seafood", "/seafood/index"],
                              ["ballot", "Fruits", "/fruits/index"],
                              ["ballot", "Vegetables", "/vegetables/index"],
                              ["ballot", "Only At Villa", "/onlyatvilla/index"],
  
                              // ["ballot", "Fruits & Vegetables", "/fruitsandvegetables/index"],
                              ["ballot", "Special Offer", "/specialoffer/index"],
                              // ["ballot", "Iceland", "/Iceland/index"],
                              // ["ballot", "Monoprix", "/Monoprix/index"],
                              // ["ballot", "Woolworths", "/Woolworths/index"],
                              // ["ballot", "Festive Items", "/festiveitems/edit"],
                              ["ballot", "Gourmet Line", "/gourmetline/edit"],
                              ["ballot", "Article", "/article/index"],
                              // ["ballot", "Hampers & Flowers", "/hampersflowers/edit"],
                              // ['report', "About", "/about"]
                              // ["ballot", "Campaign Tracking", "/campaigntracking"],
                          ]
                          this.menus.push(["ballot", "Geo Content", "/geocontent/index"])
                          this.menus.push(["ballot", "Product Meta", "/geocontent/product"])
                          this.menus.push(["ballot", "Send Noti", "/sendnoti"])
                          this.menus.push(["ballot", "Campaign Content", "/campaignscontent/index"])
                          this.menus.push(["ballot", "Campaign Tracking", "/campaigntracking"])
                          this.menus.push(["ballot", "Survey", "/survey/index"])
                          this.menus.push(["ballot", "Product Rating", "/productrating/index"])
                      }
                      break;
              }
          },
          onClickLogOff() {
              this.$store.dispatch("doLogout");
          },
      },
      computed: {
          version() {
              return process.env.VUE_APP_VERSION;
          },
          title() {
              return (this.menuName ?? "").trim() == "" ? "Villa backend" : this.menuName;
              // return process.env.VUE_APP_TITLE;
          },
          userCode() {
              return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
          },
          userGroup() {
              return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
          },
          userType() {
              return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
          },
          userBrcode() {
              return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
          }
      }
  };
  </script>
  
  <style></style>
  